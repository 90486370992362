import React, { useState, useEffect } from 'react';
import './index.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from './constants/Navbar';
import ProgressSlider from './constants/ProgressSlider';
import Footer from './constants/Footer';
import GetInTouch from './components/GetInTouch';
import BannerMiddle from './components/BannerMiddle';
import EditorAru from './components/EditorAru';
import Preloader from './components/Preloader';
import TheGrill from './components/TheGrill';
import ArtistAmol from './components/ArtistAmol';
import AdvBanner2 from './components/AdvBanner2';
import RepublicDay from './components/RepublicDay';
import RepublicDayTop from './components/RepublicDayTop';
import ToolsSlider from './components/ToolsSlider';
import ServicesAdv from './components/ServicesAdv';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 3 seconds delay
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="overflow-x-hidden antialiased selection:bg-cyan-300 selection:text-cyan-900 relative">
          <div className="absolute top-0 z-[-2] h-screen w-screen bg-white "></div>
          <div className="container  mx-auto py-8 px-4 md:px-6 lg:px-8">
            <Navbar />
            <div className="space-y-8">
            
              <div className="animated">
                <RepublicDay />
              </div>
              <div className="animated">
                <ServicesAdv />
              </div>
              {/* <div className="animated">
                <ToolsSlider />
              </div> */}
              <div className="animated">
                <AdvBanner2 />
              </div>
              <div className="animated">
                <EditorAru />
              </div>
              <div className="animated">
                <ArtistAmol />
              </div>
              <div className="animated">
                <BannerMiddle />
              </div>

              <div className="animated">
                <TheGrill />
              </div>

              <div className="animated">
                <GetInTouch />
              </div>
            </div>
          </div>
          <ProgressSlider />
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
