import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import KitesLogo from "../assets/logo/Nexkites NameKiteBlue.png";
import InvoiceBG from "../assets/invoice/Nexkites Invoice.png";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import KitesQR from "../assets/qr/KitesQR.png"
import { Link } from "react-router-dom";

const Invoice = () => {
    const [formData, setFormData] = useState({
        billedTo: "",
        address: "",
        email: "",
        phone: "",
        projectTitle: "",
        invoiceNumber: "",
        issueDate: "",
        billingDate: "",
        items: [
            { description: "", price: 0, discount: 0, igst: 18 },
        ],
    });

    // Function to generate a unique invoice number
    const generateInvoiceNumber = () => {
        const randomString = Math.random().toString(36).substr(2, 9); // Generates a random string
        return `NEXKITESINO-${randomString.toUpperCase()}`;
    };

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            invoiceNumber: generateInvoiceNumber(),
        }));
    }, []); // Generate invoice number on component mount

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleItemChange = (index, field, value) => {
        const items = [...formData.items];
        items[index][field] = value;
        setFormData((prev) => ({ ...prev, items }));
    };

    const addItem = () => {
        setFormData((prev) => ({
            ...prev,
            items: [...prev.items, { description: "", price: 0, discount: 0, igst: 18 }],
        }));
    };

    const calculateTotals = () => {
        let totalExclIGST = 0;
        let totalIGST = 0;

        formData.items.forEach((item) => {
            const discountedPrice = item.price - item.discount;
            const igst = (discountedPrice * item.igst) / 100;
            totalExclIGST += discountedPrice;
            totalIGST += igst;
        });

        return {
            totalExclIGST,
            totalIGST,
            grandTotal: totalExclIGST + totalIGST,
        };
    };

    const generatePDF = () => {
        const invoiceElement = document.getElementById("invoice-preview");

        // Fixed A4 dimensions in pixels (approx. for 96 DPI screens)
        const A4_WIDTH = 794; // A4 width in pixels
        const A4_HEIGHT = 1123; // A4 height in pixels

        html2canvas(invoiceElement, {
            useCORS: true,
            scale: 2, // Increase scale for better quality
        }).then((canvas) => {
            // Adjust canvas dimensions to fit A4 size
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const scaleFactor = Math.min(A4_WIDTH / canvasWidth, A4_HEIGHT / canvasHeight);

            const adjustedWidth = canvasWidth * scaleFactor;
            const adjustedHeight = canvasHeight * scaleFactor;

            // Resize the canvas output
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210; // A4 width in mm
            const imgHeight = (adjustedHeight * imgWidth) / adjustedWidth;

            // Start aligning content from the top
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save("Nexkites Invoice.pdf");
        });
    };



    const { totalExclIGST, totalIGST, grandTotal } = calculateTotals();

    return (
        <>
            <Navbar className="mb-16" />
            <div className="mt-16 p-10">
                <Link to="/AdminDashboard">
                    <button className="bg-black text-white px-6 py-3 mt-6 mx-auto block rounded hover:bg-black">
                        Go To Dashboard
                    </button>
                </Link>
            </div>
            <div className="relative bg-peach-100 mt-8 min-h-screen p-6">


                <div className="relative z-10">
                    {/* Invoice Editor */}
                    <div className="max-w-4xl mx-auto bg-peach-100 p-8 shadow-md mb-6 border border-cyan-300">
                        {/* Header with Logo and Company Details */}
                        <div className="flex justify-between items-center border-b pb-4 mb-4">
                            <div>
                                <img
                                    src={KitesLogo}
                                    alt="Nexkites Logo"
                                    className="h-12 mb-2"
                                />
                                <div className="text-sm text-black">
                                    <p>Nexkites Pvt Ltd</p>
                                    <p>Chh. Sambhajinagar, MH. IN. 431001</p>
                                    <p>nexkites@gmail.com</p>
                                    <p>+91 78755 97188</p>
                                    <p>www.nexkites.com</p>
                                    <p>IGST:000010163</p>
                                </div>
                            </div>
                            <div className="text-right">
                                <p className="text-black">Invoice #: {formData.invoiceNumber}</p>
                                <p className="text-black">Issue Date: {formData.issueDate}</p>
                                <p className="text-black">Next Billing Date: {formData.billingDate}</p>
                            </div>
                        </div>

                        {/* Billing Information */}
                        <div className="grid grid-cols-2 gap-4 mb-6">
                            <div>
                                <label className="block text-sm font-semibold">Billed To</label>
                                <input
                                    name="billedTo"
                                    value={formData.billedTo}
                                    onChange={handleInputChange}
                                    className="w-full border p-2 mb-2"
                                    placeholder="Client Name"
                                />
                                <label className="block text-sm font-semibold">Address</label>
                                <input
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    className="w-full border p-2 mb-2"
                                    placeholder="Client Address"
                                />
                                <label className="block text-sm font-semibold">Email</label>
                                <input
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full border p-2 mb-2"
                                    placeholder="Client Email"
                                />
                                <label className="block text-sm font-semibold">Phone</label>
                                <input
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="w-full border p-2"
                                    placeholder="Client Phone"
                                />
                                <label className="block text-sm font-semibold">Project Title</label>
                                <input
                                    name="projectTitle"
                                    value={formData.projectTitle}
                                    onChange={handleInputChange}
                                    className="w-full border p-2"
                                    placeholder="Project Title"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-semibold">Invoice Number</label>
                                <input
                                    name="invoiceNumber"
                                    value={formData.invoiceNumber}
                                    readOnly
                                    className="w-full border p-2 mb-2"
                                />
                                <label className="block text-sm font-semibold">Issue Date</label>
                                <input
                                    name="issueDate"
                                    type="date"
                                    value={formData.issueDate}
                                    onChange={handleInputChange}
                                    className="w-full border p-2 mb-2"
                                />
                                <label className="block text-sm font-semibold">Next Billing Date</label>
                                <input
                                    name="billingDate"
                                    type="date"
                                    value={formData.billingDate}
                                    onChange={handleInputChange}
                                    className="w-full border p-2"
                                />
                            </div>
                        </div>

                        {/* Items Section */}
                        <h2 className="text-lg font-bold mt-6 mb-2">Items</h2>
                        {formData.items.map((item, index) => (
                            <div className="grid grid-cols-4 gap-4 mt-2" key={index}>
                                <input
                                    placeholder="Description"
                                    value={item.description}
                                    onChange={(e) =>
                                        handleItemChange(index, "description", e.target.value)
                                    }
                                    className="border p-2"
                                />
                                <input
                                    type="number"
                                    placeholder="Price"
                                    value={item.price}
                                    onChange={(e) =>
                                        handleItemChange(index, "price", parseFloat(e.target.value) || 0)
                                    }
                                    className="border p-2"
                                />
                                <input
                                    type="number"
                                    placeholder="Discount"
                                    value={item.discount}
                                    onChange={(e) =>
                                        handleItemChange(index, "discount", parseFloat(e.target.value) || 0)
                                    }
                                    className="border p-2"
                                />
                                <input
                                    type="number"
                                    placeholder="IGST %"
                                    value={item.igst}
                                    onChange={(e) =>
                                        handleItemChange(index, "igst", parseFloat(e.target.value) || 0)
                                    }
                                    className="border p-2"
                                />
                            </div>
                        ))}
                        <button
                            onClick={addItem}
                            className="mt-4 px-4 py-2 bg-black text-white font-800 rounded"
                        >
                            Add Item
                        </button>
                    </div>

                    {/* Invoice Preview */}
                    <div
                        id="invoice-preview"
                        className="relative max-w-4xl mx-auto text-black bg-white p-8  border border-cyan-300 "
                    >


                        {/* Content */}
                        <div className="relative z-10">
                            <div className="flex justify-between items-center border-b border-cyan-300 pb-4">
                                <div>
                                    <img src={KitesLogo} alt="Nexkites Logo" className="h-12 mb-2" />
                                    <div className="text-sm font-800 text-black">
                                        <p>Nexkites Pvt Ltd.</p>
                                        <p>Chh. Sambhajinagar, MH. IN. 431001</p>
                                        <p>+91 78755 97188</p>
                                        <p>nexkites@gmail.com</p>
                                        <p>www.nexkites.com</p>
                                        <p>IGST:000010163</p>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <h1 className="text-sm font-semibold">INVOICE</h1>
                                    <p>Invoice #: {formData.invoiceNumber}</p>
                                    <p>Issue Date: {formData.issueDate}</p>
                                    <p>Next Billing Date: {formData.billingDate}</p>
                                </div>
                            </div>

                            <div className="mt-4">
                                <h2 className="font-semibold text-sm">Billed To:</h2>
                                <p>{formData.billedTo}</p>
                                <p>{formData.address}</p>
                                <p>{formData.email}</p>
                                <p>{formData.phone}</p>
                                <p className="text-center text-black font-semibold p-2">{formData.projectTitle}</p>
                            </div>

                            <table className="w-full my-5 p-3">
                                <thead>
                                    <tr>
                                        <th className=" py-2 my-2 font-800 text-center text-sm">Description</th>
                                        <th className=" py-2 my-2 font-800 text-right text-sm">Price</th>
                                        <th className=" py-2 my-2 font-800 text-right text-sm">Discount</th>
                                        <th className=" py-2 my-2 font-800 text-right text-sm">IGST %</th>
                                        <th className=" py-2 my-2 font-800 text-right text-sm">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.items.map((item, index) => {
                                        const discountedPrice = item.price - item.discount;
                                        const igst = (discountedPrice * item.igst) / 100;
                                        const total = discountedPrice + igst;

                                        return (
                                            <tr key={index} className="font-800 text-sm">
                                                <td className=" text-left">{item.description}</td>
                                                <td className=" text-right">₹{item.price}</td>
                                                <td className=" text-right">₹{item.discount}</td>
                                                <td className=" text-right">{item.igst}%</td>
                                                <td className=" text-right">₹{total.toFixed(2)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            <div className="text-right mt-4">
                                <p>Total excl. IGST: ₹{totalExclIGST.toFixed(2)}</p>
                                <p>IGST: ₹{totalIGST.toFixed(2)}</p>
                                <h2 className="text-lg font-semibold">Grand Total: ₹{grandTotal.toFixed(2)}</h2>
                            </div>

                            {/* Payment Details Section */}
                            <div className="mt-6 border-t pt-4 flex flex-row justify-between items-center">
                                <div className="flex flex-col items-center">
                                    <h3 className="text-sm font-semibold mb-2">Payment Details</h3>

                                    {/* Bank Transfer Details */}
                                    <div className="text-left mb-4">
                                        <p className="text-sm font-semibold">Bank Transfer Details:</p>
                                        <p className="text-sm">Bank Name: Nexkites Bank</p>
                                        <p className="text-sm">Account Number: 123456789012</p>
                                        <p className="text-sm">IFSC Code: NEXKITES001</p>
                                        <p className="text-sm">Account Holder Name: Nexkites Pvt Ltd</p>
                                    </div>
                                </div>

                                {/* QR Code */}
                                <div className="flex flex-col items-center mb-4">
                                    <p className="text-sm font-semibold mb-2">Scan QR Code to Pay:</p>
                                    <img
                                        src={KitesQR} // Replace with your actual QR code image URL
                                        alt="Payment QR Code"
                                        className="mx-auto h-24 w-24"
                                    />
                                </div>

                                {/* Terms and Conditions */}
                                <div className="flex flex-col items-center">
                                    <h4 className="text-sm font-semibold mb-2">Terms and Conditions:</h4>
                                    <ul className="text-sm">
                                        <li>Payment is due within 15 days of the invoice date.</li>
                                        <li>Late payments will incur a 2% interest fee per month.</li>
                                        <li>All transactions are subject to Nexkites Pvt Ltd's Terms and Conditions.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="text-sm text-black py-5">
                                <p>
                                    Note: This is computer generated bill and autosigned.
                                </p>
                            </div>

                        </div>
                    </div>

                    <button
                        onClick={generatePDF}
                        className="bg-black text-white px-6 py-3 mt-6 mx-auto block rounded hover:bg-black"
                    >
                        Get Invoice
                    </button>
                    <Link to="/AdminDashboard">
                        <button className="bg-black text-white px-6 py-3 mt-6 mx-auto block rounded hover:bg-black">
                            Go To Dashboard
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Invoice;
