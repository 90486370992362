import React from 'react';
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faPalette,
    faMobileAlt,
    faFilm,
    faPaintBrush,
} from '@fortawesome/free-solid-svg-icons';
import ProgressSlider from '../constants/ProgressSlider';

const Pricing = () => {
    const navigate = useNavigate();

    const plans = [
        {
            title: 'Websites',
            description: 'Explore our website development services to boost your online presence. We offer custom designs, SEO optimization, and responsive websites.',
            price: '₹4999.00',
            extraInfo: 'Up to one year maintenance, excl. tax',
            discount: 'Save 40%',
            details: [
                'Custom website design and development',
                'Responsive design for mobile and desktop',
                'SEO-friendly structure to improve visibility',
                'Custom domain setup and hosting',
                'Google Analytics integration',
                'Content management system (CMS) integration',
                'Regular security updates and backups',
            ],
            icon: faGlobe,
            iconColor: 'text-blue-600',
            bgColor: 'bg-blue-50',
        },
        {
            title: 'Mobile Applications',
            description: 'Get mobile app development services for iOS and Android. We specialize in native and hybrid apps, ensuring top-notch performance.',
            price: '₹9999.00',
            extraInfo: 'Up to one year maintenance, excl. tax',
            discount: 'Save 33%',
            details: [
                'Native and hybrid app development for iOS and Android',
                'App store optimization (ASO)',
                'Performance testing and optimization',
                'User-friendly design and seamless experience',
                'Post-launch support and updates',
                'Push notifications and real-time updates',
                'Integration with third-party APIs',
            ],
            icon: faMobileAlt,
            iconColor: 'text-green-500',
            bgColor: 'bg-green-50',
        },
        {
            title: 'Product Marketing',
            description: 'Boost your product’s visibility and drive sales with our comprehensive marketing services, including market research, campaigns, and SEO.',
            price: '₹15000.00',
            details: [
                'Market research and competitor analysis',
                'Social media strategy and campaigns (Facebook, Instagram, LinkedIn)',
                'Search engine optimization (SEO) & search engine marketing (SEM)',
                'Email marketing, newsletters, and automation',
                'Pay-per-click advertising (PPC)',
                'Influencer marketing and partnerships',
                'Content marketing and blogging',
                'Analytics and reporting for continuous improvement',
            ],
            icon: faPalette,
            iconColor: 'text-orange-600',
            bgColor: 'bg-orange-50',
        },
        {
            title: 'Cloud Storage & Infrastructure Solutions',
            description: 'Comprehensive cloud storage and infrastructure management services with flexible pricing based on usage.',
            price: '₹10000.00/month',
            details: [
                'End-to-end cloud infrastructure management',
                'Scalable and secure cloud storage solutions',
                '24/7 monitoring and support',
                'Disaster recovery and data backup solutions',
                'Customizable storage and bandwidth options',
                'Cloud security and compliance management',
                'Automatic scaling to meet business needs',
            ],
            icon: faGlobe,
            iconColor: 'text-blue-500',
            bgColor: 'bg-blue-100',
            pricingTiers: [
                {
                    tier: 'Basic',
                    price: '₹10,000/month',
                    storage: 'Up to 1 TB',
                    features: [
                        'Basic cloud storage',
                        'Standard support',
                        'Up to 1 TB bandwidth',
                        'Data backup and recovery',
                        'Basic security features',
                    ],
                },
                {
                    tier: 'Standard',
                    price: '₹25,000/month',
                    storage: 'Up to 5 TB',
                    features: [
                        'Enhanced cloud storage',
                        'Priority support',
                        'Up to 5 TB bandwidth',
                        'Automatic scaling',
                        'Advanced data encryption',
                        'Backup and disaster recovery solutions',
                    ],
                },
                {
                    tier: 'Premium',
                    price: '₹50,000/month',
                    storage: 'Up to 10 TB',
                    features: [
                        'Premium cloud storage with guaranteed uptime',
                        '24/7 dedicated support',
                        'Up to 10 TB bandwidth',
                        'Advanced security features (encryption, compliance)',
                        'Global content delivery network (CDN)',
                        'Advanced disaster recovery solutions',
                    ],
                },
                {
                    tier: 'Enterprise',
                    price: 'Custom pricing',
                    storage: 'Above 10 TB (customizable)',
                    features: [
                        'Fully customizable cloud infrastructure',
                        'Custom storage and bandwidth allocation',
                        'Enterprise-level security and compliance (ISO, SOC)',
                        'Personalized support and consulting',
                        'Global CDN and disaster recovery options',
                        'Dedicated infrastructure management team',
                    ],
                },
            ],
        },
        {
            title: 'Advanced Business Analytics',
            description: 'Unlock insights and make data-driven decisions with our analytics solutions tailored to your business needs.',
            price: '₹25000.00',
            details: [
                'Advanced data visualization and dashboards',
                'Predictive analytics for future trends',
                'Custom reporting and business intelligence',
                'Data modeling and AI-powered insights',
                'Data warehousing and ETL (Extract, Transform, Load)',
                'Real-time analytics and data streaming',
                'KPI tracking and goal management',
            ],
            icon: faPalette,
            iconColor: 'text-indigo-600',
            bgColor: 'bg-indigo-50',
        },
        {
            title: 'AI & Machine Learning Solutions',
            description: 'Revolutionize your business with AI-driven solutions for automation, data analysis, and predictive modeling.',
            price: '₹35000.00',
            details: [
                'Custom AI models and machine learning algorithms',
                'Natural language processing (NLP) for automation',
                'AI-powered data analysis and predictive modeling',
                'Computer vision and image recognition',
                'Automation of repetitive tasks with AI',
                'Data mining and pattern recognition',
                'AI-driven decision support systems',
            ],
            icon: faPalette,
            iconColor: 'text-red-600',
            bgColor: 'bg-red-50',
        },
        {
            title: 'Backend Development',
            description: 'Build scalable and secure backend systems that power your applications and support growth.',
            price: '₹20000.00',
            details: [
                'Custom API development and integration',
                'Database design, management, and optimization',
                'Scalable server architecture and microservices',
                'Cloud-native development for efficient scaling',
                'Security measures (OAuth, JWT, encryption)',
                'Load balancing and fault tolerance',
                'Continuous integration and deployment (CI/CD)',
            ],
            icon: faGlobe,
            iconColor: 'text-teal-600',
            bgColor: 'bg-teal-100',
        },
        {
            title: 'UI/UX Designing',
            description: 'Transform your ideas into intuitive, user-friendly designs that enhance user experience.',
            price: '₹7999.00',
            details: [
                'Custom UI/UX design tailored to your brand and goals',
                'Wireframing and rapid prototyping',
                'User research, persona creation, and user testing',
                'High-fidelity mockups and design systems',
                'Responsive and adaptive design for all devices',
                'Collaborative design process with iterative feedback',
                'Design handoff with detailed specs for developers',
            ],
            icon: faPalette,
            iconColor: 'text-pink-500',
            bgColor: 'bg-pink-50',
        },
        {
            title: 'Graphic Design',
            description: 'Creative and impactful graphic design solutions for your marketing and branding needs.',
            price: '₹9999.00',
            details: [
                'Logo design and corporate branding',
                'Custom illustrations and artwork',
                'Social media graphics and marketing materials',
                'Infographics, brochures, and posters',
                'Packaging and product design',
                'Web graphics and UI elements',
                'Business card and stationery design',
            ],
            icon: faPaintBrush,
            iconColor: 'text-purple-600',
            bgColor: 'bg-purple-50',
        },
        {
            title: 'Video & Animations',
            description: 'Stand out with custom videos and animations designed to tell your story and captivate your audience.',
            price: '₹4999.00',
            details: [
                'Explainer videos and tutorials',
                '2D and 3D animations',
                'Motion graphics and visual effects',
                'Video editing and post-production',
                'Scriptwriting, storyboarding, and direction',
                'Brand videos and commercials',
                'Social media video content',
            ],
            icon: faFilm,
            iconColor: 'text-yellow-500',
            bgColor: 'bg-yellow-50',
        },
    ];


    return (
        <>
            <Navbar className="mb-10" />
            <div className="py-16 mt-10 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] selection:bg-cyan-300 selection:text-cyan-900">
                <div className="text-center mb-16 px-4">
                    <h1 className="text-4xl font-800 text-gray-700 mb-10">Our Pricing Plans</h1>
                    <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
                        Choose the perfect plan for your business needs. We offer competitive and flexible
                        packages to help you succeed.
                    </p>
                    <p className="text-sm text-red-500 mt-3">
                        *Note: Prices may vary based on project scope and requirements.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-8 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] rounded-2xl shadow-lg">
                    {plans.map((plan, index) => (
                        <div
                            key={index}
                            className={`flex flex-col justify-between w-full p-8 rounded-xl shadow-md border border-gray-200 ${plan.bgColor} transform transition-all hover:scale-105 hover:shadow-xl`}
                        >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={plan.icon}
                                    className={`text-6xl mb-5 ${plan.iconColor}`}
                                />
                                <h3 className="text-2xl font-semibold">{plan.title}</h3>
                                <p className="text-gray-600 my-4">{plan.description}</p>
                            </div>

                            <div className="text-center my-6">
                                <span className="text-lg font-800 text-green-600 p-5">Starting at</span>
                                <div className="text-2xl p-2 font-800">{plan.price} <span className="text-lg">INR</span></div>
                                {plan.extraInfo && (
                                    <p className="text-sm text-gray-500">{plan.extraInfo}</p>
                                )}
                                {plan.discount && (
                                    <span className="bg-green-100 text-green-800 text-sm px-3 py-1 mt-2 rounded inline-block">
                                        {plan.discount}
                                    </span>
                                )}
                            </div>

                            <ul className="space-y-2 text-gray-700 mb-6">
                                {plan.details.map((detail, idx) => (
                                    <li key={idx} className="flex items-center gap-2">
                                        <span className="w-2 h-2 bg-black rounded-full inline-block" />
                                        {detail}
                                    </li>
                                ))}
                            </ul>

                            <button
                                className="mt-auto w-full py-3 bg-black text-white font-800 rounded-md hover:bg-black-800 transition"
                                onClick={() => navigate('/support')}
                            >
                                Get Started
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <ProgressSlider />
            <Footer />
        </>
    );
};

export default Pricing;
