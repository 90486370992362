import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration (replace placeholders with your actual config)
const firebaseConfig = {
    apiKey: "AIzaSyCd6wZCgWwJ06si8gumAUf0sGPGi9kvatg",
    authDomain: "nexkites-38efb.firebaseapp.com",
    databaseURL: "https://nexkites-38efb-default-rtdb.firebaseio.com",
    projectId: "nexkites-38efb",
    storageBucket: "nexkites-38efb.firebasestorage.app",
    messagingSenderId: "195362736405",
    appId: "1:195362736405:web:752015c65e9e56c013411c",

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Export database and storage instances
export const database = getDatabase(app);
export const storage = getStorage(app);

// Initialize Providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();


export { auth, db, googleProvider, facebookProvider };

