import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DesktopImage1 from "../assets/newyear/6.png";
import DesktopImage2 from "../assets/newyear/7.png";
import DesktopImage3 from "../assets/newyear/8.png";
import DesktopImage4 from "../assets/newyear/9.png"; // Added a new image

const Newyear = () => {
    const navigate = useNavigate();
    const slides = [
        {
            image: DesktopImage1,
            heading: "Embark on a journey towards excellence!",
            subheading: "Discover opportunities that align with your passions!",
            buttonText: "Get Started",
            link: "/careers",
        },
        {
            image: DesktopImage2,
            heading: "Let’s Build Tomorrow’s Web Solutions Today!",
            subheading: "Because your brand deserves the best.",
            buttonText: "Get Started",
            link: "/careers",
        },
        {
            image: DesktopImage3,
            heading: "Showcase Your Products!",
            subheading: "Elevate your brand with beautiful displays.",
            buttonText: "Get Started",
            link: "/careers",
        },
        {
            image: DesktopImage4, // Added new slide
            heading: "Kickstart the New Year with Bold Ideas!",
            subheading: "Your vision, our execution – let's make it happen.",
            buttonText: "Get Started",
            link: "/careers",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 2000); // Change slide every 2 seconds

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <section className="bg-cyan-20 text-center px-4 py-5 sm:px-8 lg:px-12 mt-16 rounded"> {/* Added margin-top */}
            {/* Slider Content */}
            <div className="relative z-10">
                <h1 className="text-gray-400 font-800 text-3xl sm:text-4xl lg:text-4xl leading-tight">
                    {slides[currentIndex].heading}
                </h1>

                {/* Image */}
                <div className="flex justify-center my-8">
                    <img
                        src={slides[currentIndex].image}
                        alt="Hero Slider"
                        className="max-w-full w-auto h-auto rounded-lg"
                    />
                </div>

                {/* Subtitle */}
                <p className="text-xl text-gray-700 font-light">
                    {slides[currentIndex].subheading}
                </p>

                {/* Button to Services Page */}
                <div className="mt-6">
                    <Link to="/services">
                        <button className="bg-black text-white rounded-md px-6 py-2 font-800 text-sm sm:px-8 sm:py-3 md:px-10 md:py-4 hover:bg-[#d83299] transition duration-300">
                            Explore Our Services
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Newyear;
