import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import RepublicDayImage1 from "../assets/Republic day.png";
 // Added a new image

const RepublicDay = () => {
    const navigate = useNavigate();
    const slides = [
        {
            image: RepublicDayImage1,
            heading: "Celebrate India’s Republic Day!",
            subheading: "Proudly honoring our nation’s spirit and diversity.",
            buttonText: "Discover",
            link: "/services",
        },
        {
            image: RepublicDayImage1,
            heading: "Unity in Diversity!",
            subheading: "Join us in commemorating India’s rich heritage.",
            buttonText: "Explore",
            link: "/services",
        },
        {
            image: RepublicDayImage1,
            heading: "Tribute to Our Constitution!",
            subheading: "Celebrate the pillars of democracy with us.",
            buttonText: "Discover",
            link: "/services",
        },
        {
            image: RepublicDayImage1, // Added new slide
            heading: "Saluting the Heroes of India!",
            subheading: "Together, let’s honor our freedom fighters.",
            buttonText: "Explore",
            link: "/services",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 2000); // Change slide every 2 seconds

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <section className="text-center px-4 py-5 sm:px-8 lg:px-12 mt-16 "> {/* Updated background color */}
            {/* Slider Content */}
            <div className="relative z-10">
                <h1 className="text-orange-500 font-800 text-3xl sm:text-4xl lg:text-4xl leading-tight">
                    {slides[currentIndex].heading}
                </h1>

                {/* Image */}
                <div className="flex justify-center my-8">
                    <img
                        src={slides[currentIndex].image}
                        alt="Republic Day Slider"
                        className="max-w-full w-auto h-auto "
                    />
                </div>

                {/* Subtitle */}
                <p className="text-xl text-gray-800 font-light">
                    {slides[currentIndex].subheading}
                </p>

                {/* Button to Republic Day Page */}
                <div className="mt-6">
                    <Link to={slides[currentIndex].link}>
                        <button className="bg-orange-500 text-white rounded-md px-8 py-1 font-800 text-lg sm:px-8 sm:py-3 md:px-10 md:py-4  transition duration-300">
                            {slides[currentIndex].buttonText}
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default RepublicDay;
