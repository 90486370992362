import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FaTasks, FaUser, FaSignOutAlt, FaProjectDiagram, FaDollarSign, FaFileInvoice, FaRupeeSign } from "react-icons/fa";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import AkashSadashivImg from "../assets/ceo/CEONexkites.jpg";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components for pie chart
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState("Dashboard");
  const [tasks, setTasks] = useState([
    { task: "Design homepage", employee: "Revati Joshi", progress: 50, deadline: "2024-12-10", dates: ["2024-12-01", "2024-12-05", "2024-12-10"], progressOverTime: [30, 45, 50] },
    { task: "Client meeting", employee: "Tanvi Gupta", progress: 70, deadline: "2024-12-12", dates: ["2024-12-01", "2024-12-05", "2024-12-12"], progressOverTime: [40, 60, 70] },
  ]);
  const [employees] = useState([
    { name: "Revati Joshi", role: "Frontend Developer", id: "EMP12345" },
    { name: "Tanvi Gupta", role: "UI/UX Designer", id: "EMP34567" },
    { name: "Aryan Shah", role: "Backend Developer", id: "EMP67890" },
    { name: "Priya Nair", role: "Graphic Designer", id: "EMP11223" },
    { name: "Kunal Mehta", role: "Project Manager", id: "EMP33445" },
    { name: "Ananya Verma", role: "Content Strategist", id: "EMP55667" },
    { name: "Rohit Kapoor", role: "Mobile Developer", id: "EMP77889" },
    { name: "Sneha Patil", role: "QA Tester", id: "EMP99100" },
  ]);
  const [projects] = useState([
    { name: "Website Redesign", deadline: "2024-12-15", status: "Ongoing", progress: 70 },
    { name: "Mobile App Launch", deadline: "2024-12-20", status: "Ongoing", progress: 40 },
  ]);
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login-page");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "Dashboard":
        return (
          <>
            <h2 className="text-xl font-semibold text-gray-700 mb-6">Dashboard</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
              <div className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-gray-600 text-sm">Assigned Tasks</h3>
                <p className="text-lg font-semibold text-gray-800">{tasks.length}</p>
              </div>
              <div className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-gray-600 text-sm">Live Clients</h3>
                <p className="text-lg font-semibold text-gray-800">12</p>
              </div>
            </div>

            <section className="mb-8">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Ongoing Projects</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                {projects.map((project, index) => (
                  <div key={index} className="bg-white shadow-md rounded-lg p-6">
                    <h4 className="text-gray-800 font-semibold">{project.name}</h4>
                    <p className="text-gray-600 text-sm">Deadline: {project.deadline}</p>
                    <p className="text-gray-600 text-sm">Status: {project.status}</p>
                    <p className="text-gray-600 text-sm">Progress: {project.progress}%</p>
                  </div>
                ))}
              </div>
            </section>
          </>
        );

      case "Employees":
        return (
          <section>
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Employee Progress Analytics</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {employees.map((employee) => {
                const employeeTasks = tasks.filter(task => task.employee === employee.name);
                const employeeProgress = employeeTasks.map(task => task.progress);
                const avgProgress = employeeProgress.reduce((acc, curr) => acc + curr, 0) / employeeProgress.length || 0;

                return (
                  <div key={employee.id} className="bg-white shadow-md rounded-lg p-6">
                    <h4 className="text-gray-800 font-semibold">{employee.name}</h4>
                    <p className="text-gray-600 text-sm">Role: {employee.role}</p>
                    <p className="text-gray-600 text-sm">ID: {employee.id}</p>

                    {/* Employee Task Progress Analytics - Pie Chart */}
                    <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
                      <h5 className="text-xl font-semibold text-gray-800 mb-4">Average Task Progress</h5>
                      <Pie
                        data={{
                          labels: ["Progress", "Remaining"],
                          datasets: [
                            {
                              label: `${employee.name}'s Progress`,
                              data: [avgProgress, 100 - avgProgress], // Display progress vs remaining
                              backgroundColor: ["#4CAF50", "#D3D3D3"], // Green for progress, light gray for remaining
                              hoverBackgroundColor: ["#66BB6A", "#B0BEC5"], // Slightly darker on hover
                              borderColor: ["#388E3C", "#A4A4A4"], // Darker border for better contrast
                              borderWidth: 2,
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            tooltip: {
                              backgroundColor: "rgba(0, 0, 0, 0.7)", // Tooltip background
                              titleColor: "#fff", // Tooltip title color
                              bodyColor: "#fff", // Tooltip body color
                            },
                          },
                        }}
                      />
                    </div>

                    {/* Pie Chart for Task Progress Over Time */}
                    <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
                      <h5 className="text-xl font-semibold text-gray-800 mb-4">Task Progress Over Time</h5>
                      <Pie
                        data={{
                          labels: employeeTasks.map((task) => task.dates).flat(),
                          datasets: [{
                            label: 'Task Progress',
                            data: employeeTasks.map((task) => task.progressOverTime).flat(),
                            backgroundColor: '#FF9800', // Orange color for the pie sections
                            hoverBackgroundColor: '#FFB74D', // Lighter orange on hover
                            borderColor: '#F57C00', // Darker orange for borders
                            borderWidth: 2,
                          }],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            tooltip: {
                              backgroundColor: "rgba(0, 0, 0, 0.7)", // Tooltip background
                              titleColor: "#fff", // Tooltip title color
                              bodyColor: "#fff", // Tooltip body color
                            },
                          },
                        }}
                      />
                    </div>

                  </div>
                );
              })}
            </div>
          </section>
        );

      case "Invoice":
        return (
          <div>
            <h2 className="text-xl font-semibold text-gray-700 mb-6">Invoice Generator</h2>
            {/* Here you can add your Invoice generation component */}
            <p>Implement your Invoice generation functionality here.</p>
          </div>
        );

      default:
        return <div>404 - Page Not Found</div>;
    }
  };

  return (
    <>
      <Navbar className="mb-16" />
      <div className="min-h-screen mt-16 py-10 bg-gray-100 flex flex-col lg:flex-row">
        {/* Sidebar */}
        <aside className="w-full lg:w-64 bg-white shadow-md p-6">
          <div className="text-center">
            <img src={AkashSadashivImg} alt="CEO" className="w-24 h-24 rounded-full mx-auto mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Akash Sadashiv</h3>
            <p className="text-gray-600">CEO & Founder</p>
          </div>
          <nav className="mt-8">
            <ul className="space-y-4">
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-gray-600 hover:text-gray-800 ${activeSection === "Dashboard" && "text-gray-800 font-semibold"}`}
                  onClick={() => setActiveSection("Dashboard")}
                >
                  <FaTasks className="text-lg" />
                  <span>Dashboard</span>
                </button>
              </li>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-gray-600 hover:text-gray-800 ${activeSection === "Employees" && "text-gray-800 font-semibold"}`}
                  onClick={() => setActiveSection("Employees")}
                >
                  <FaUser className="text-lg" />
                  <span>Employees</span>
                </button>
              </li>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-gray-600 hover:text-gray-800 ${activeSection === "Projects" && "text-gray-800 font-semibold"}`}
                  onClick={() => setActiveSection("Projects")}
                >
                  <FaProjectDiagram className="text-lg" />
                  <span>Projects</span>
                </button>
              </li>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-gray-600 hover:text-gray-800 ${activeSection === "Invoice" && "text-gray-800 font-semibold"}`}
                  onClick={() => setActiveSection("Invoice")}
                >
                  <FaFileInvoice className="text-lg" />
                  <span>Invoice</span>
                </button>
              </li>
              <li>
                <button
                  className="flex items-center space-x-2 w-full text-gray-600 hover:text-gray-800"
                  onClick={handleLogout}
                >
                  <FaSignOutAlt className="text-lg" />
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="w-full lg:w-3/4 p-6">{renderContent()}</main>
      </div>
      <Footer />
    </>
  );
};

export default AdminDashboard;
