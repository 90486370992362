import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt, faPaintBrush, faChartLine, faCloud, faDatabase, faRobot, faImage, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import { useNavigate } from 'react-router-dom';
import ProgressSlider from '../constants/ProgressSlider';
import AnimatedImage from '../components/AnimatedImage';
import KiteFest from '../components/KiteFest';

const ProductsSection = () => {
    const navigate = useNavigate();

    const products = [
        {
            title: 'Web Development',
            icon: faDesktop,
            description: `Develop fast, scalable, and responsive websites using modern frameworks and technologies like React, Next.js, and JAMstack for a seamless user experience across all devices.`,
            features: [
                'Dynamic single-page applications with React and Next.js',
                'Progressive Web Apps (PWAs) for offline-first experiences',
                'Static site generation with Next.js for fast load times',
                'SEO-friendly architecture with server-side rendering',
                'Real-time functionality with WebSockets',
                'Integration with headless CMS like Strapi or Contentful',
            ],
            color: 'text-blue-500',
        },
        {
            title: 'Mobile Apps Development',
            icon: faMobileAlt,
            description: `Build high-performance mobile applications for iOS and Android using Flutter, React Native, or Swift with a focus on seamless user experience and performance.`,
            features: [
                'Cross-platform mobile apps with React Native or Flutter',
                'Native iOS (Swift) and Android (Flutter) development',
                'Firebase for real-time database, authentication, and analytics',
                'Secure and HIPAA-compliant solutions for healthcare',
                'In-app payments and subscriptions (Stripe, PayPal)',
                'Push notifications and deep linking for user engagement',
            ],
            color: 'text-red-500',
        },
        {
            title: 'UI/UX Design',
            icon: faPaintBrush,
            description: `Create stunning, user-friendly interfaces and designs that focus on usability, accessibility, and mobile-first approaches using Figma, Adobe XD, and Sketch.`,
            features: [
                'Interactive wireframes and prototypes with Figma',
                'Design systems for scalable, consistent user interfaces',
                'User-centered design principles for mobile-first development',
                'Rapid prototyping and user testing for better iterations',
                'Accessibility-first design ensuring WCAG compliance',
                'Branding and visual design with Adobe XD and Illustrator',
            ],
            color: 'text-green-500',
        },
        {
            title: 'Cloud Solutions',
            icon: faCloud,
            description: `Leverage the power of cloud computing with AWS, Google Cloud, and Azure to scale your applications and ensure high availability and security.`,
            features: [
                'Infrastructure as a Service (IaaS) using AWS, GCP, or Azure',
                'Serverless computing with AWS Lambda or Google Cloud Functions',
                'Cloud-native application architecture with Kubernetes and Docker',
                'Continuous integration/continuous deployment (CI/CD) pipelines',
                'Managed cloud databases (DynamoDB, Firestore, etc.)',
                'Data encryption, security, and backup strategies',
            ],
            color: 'text-gray-500',
        },
        {
            title: 'Digital Marketing',
            icon: faBullhorn,
            description: `Boost your business growth with data-driven digital marketing strategies, leveraging tools like Google Ads, Facebook Ads, SEO, and content marketing.`,
            features: [
                'Targeted advertising on Google, Facebook, and LinkedIn',
                'Search Engine Optimization (SEO) with content optimization',
                'Email marketing automation with Mailchimp or ActiveCampaign',
                'Video marketing, webinars, and live-streaming solutions',
                'Social media management with Buffer or Hootsuite',
                'Conversion rate optimization (CRO) with A/B testing',
            ],
            color: 'text-orange-500',
        },
        {
            title: 'Graphic & Visual Design',
            icon: faImage,
            description: `Design captivating visual assets for your brand identity, including logos, marketing materials, and social media graphics using Adobe Photoshop, Illustrator, and Canva.`,
            features: [
                'Custom logo design and branding packages',
                'Social media graphics, banners, and post templates',
                'Print design for flyers, brochures, and business cards',
                'Infographics, illustrations, and marketing materials',
                'Video editing and motion graphics using After Effects',
                'Consistent brand storytelling across all platforms',
            ],
            color: 'text-pink-500',
        },
        {
            title: 'Advanced Analytics & BI',
            icon: faChartLine,
            description: `Unlock actionable insights with advanced business intelligence tools like Power BI, Tableau, and Google Data Studio for data visualization and predictive analytics.`,
            features: [
                'Custom data dashboards with Power BI or Tableau',
                'Real-time data analysis with Google Data Studio',
                'Predictive analytics and machine learning models',
                'Data integration from multiple sources (CRM, ERP, etc.)',
                'Interactive visualizations and drill-down capabilities',
                'Automated reporting and email alerts for key metrics',
            ],
            color: 'text-indigo-500',
        },
        {
            title: 'AI & ML Solutions',
            icon: faRobot,
            description: `Leverage artificial intelligence and machine learning to automate processes, enhance decision-making, and provide personalized user experiences using TensorFlow, PyTorch, and OpenAI.`,
            features: [
                'Custom machine learning models with TensorFlow and PyTorch',
                'Natural language processing (NLP) for text analysis',
                'Computer vision for object recognition with OpenCV',
                'AI-powered chatbots and virtual assistants',
                'Recommendation engines and personalization algorithms',
                'Deployable models on cloud platforms (AWS SageMaker, GCP AI)',
            ],
            color: 'text-teal-500',
        },
        {
            title: 'Backend Development',
            icon: faDatabase,
            description: `Develop scalable, secure, and high-performance back-end solutions using modern technologies like Node.js, GraphQL, PostgreSQL, and MongoDB.`,
            features: [
                'Custom API development with Node.js and Express',
                'GraphQL for flexible data querying',
                'Serverless architectures with AWS Lambda and DynamoDB',
                'Real-time web applications with WebSockets',
                'Microservices architecture with Docker and Kubernetes',
                'Database design and management with PostgreSQL and MongoDB',
            ],
            color: 'text-yellow-500',
        },
    ];

    return (
        <>
            <Navbar className="mb-16" />
           
            <div className="py-16 px-4 mt-5 sm:px-6 lg:px-8 xl:px-10 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] selection:bg-cyan-300 selection:text-cyan-900">
                {/* Heading */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-800 text-gray-700 text-center p-5 mb-10">
                        Our Services
                    </h1>
                    <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
                        We offer a diverse range of services using the latest technologies to help businesses grow and succeed in the digital age.
                    </p>
                </div>

                {/* Products Grid */}
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {products.map((product, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-lg flex flex-col h-full transform transition-all duration-300 hover:scale-105 hover:shadow-2xl hover:translate-y-2"
                        >
                            {/* Icon Section */}
                            <div className="h-32 flex justify-center items-center">
                                <FontAwesomeIcon icon={product.icon} className={`text-6xl ${product.color}`} />
                            </div>
                            {/* Title Section */}
                            <h3 className="text-2xl font-semibold mt-4 text-center text-gray-800">
                                {product.title}
                            </h3>
                            {/* Description Section */}
                            <p className="mt-2 text-gray-600 font-medium p-5 text-center">
                                {product.description}
                            </p>
                            {/* Features List Section */}
                            <ul className="mt-4 space-y-2 text-gray-700 text-sm list-disc list-inside">
                                {product.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                            {/* Button Section */}
                            <div className="mt-auto p-5 flex justify-center">
                                <button
                                    className="py-2 px-6 p-2 bg-black text-white font-800 rounded-md hover:bg-black hover:transform hover:scale-105 transition duration-200 w-full sm:w-auto"
                                    onClick={() => navigate('/pricing')}
                                >
                                    Explore
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ProgressSlider />
            <Footer />
        </>
    );
};

export default ProductsSection;
