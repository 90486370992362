import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, googleProvider, facebookProvider } from '../firebase-config'; // Firebase imports (add providers)
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome
import LOGO from "../assets/logo/Nexkites KiteBlue.png";

const LoginPage = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [userType, setUserType] = useState('client');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // For loading state
    const navigate = useNavigate();

    const predefinedCredentials = {
        admin: { email: 'ceo@nexkites.com', password: 'theceoakash#0100' },
        client: { email: 'reva@nexkites.com', password: 'therevajoshi2025' },
        employee: { email: 'reva@nexkites.com', password: 'therevajoshi2025' },
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setLoading(true);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            setErrorMessage('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        const predefinedCred = predefinedCredentials[userType];
        if (
            predefinedCred.email === formData.email &&
            predefinedCred.password === formData.password
        ) {
            const route = {
                client: '/ClientDashboard',
                admin: '/AdminDashboard',
                employee: '/EmployeeDashboard',
            }[userType];
            navigate(route);
        } else {
            try {
                const { user } = await signInWithEmailAndPassword(auth, formData.email, formData.password);
                const userDoc = await getDoc(doc(db, "users", user.uid));

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.role === userType) {
                        const route = {
                            client: '/ClientDashboard',
                            admin: '/AdminDashboard',
                            employee: '/EmployeeDashboard',
                        }[userType];
                        navigate(route);
                    } else {
                        setErrorMessage('Role mismatch. Please select the correct user type.');
                    }
                } else {
                    setErrorMessage('User data not found in the database.');
                }
            } catch (error) {
                setErrorMessage('Invalid credentials. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleGoogleLogin = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const userDoc = await getDoc(doc(db, "users", user.uid));

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role === userType) {
                    const route = {
                        client: '/ClientDashboard',
                        admin: '/AdminDashboard',
                        employee: '/EmployeeDashboard',
                    }[userType];
                    navigate(route);
                } else {
                    setErrorMessage('Role mismatch. Please select the correct user type.');
                }
            } else {
                setErrorMessage('User data not found in the database.');
            }
        } catch (error) {
            setErrorMessage('Failed to login with Google. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleFacebookLogin = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, facebookProvider);
            const user = result.user;
            const userDoc = await getDoc(doc(db, "users", user.uid));

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role === userType) {
                    const route = {
                        client: '/ClientDashboard',
                        admin: '/AdminDashboard',
                        employee: '/EmployeeDashboard',
                    }[userType];
                    navigate(route);
                } else {
                    setErrorMessage('Role mismatch. Please select the correct user type.');
                }
            } else {
                setErrorMessage('User data not found in the database.');
            }
        } catch (error) {
            setErrorMessage('Failed to login with Facebook. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <section className="flex items-center justify-center min-h-screen bg-gray-50 p-5">
                <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
                    <div className="text-center mb-8">
                        <img
                            src={LOGO}
                            alt="Nexkites Logo"
                            className="mx-auto w-12 h-12 mb-2 bounce" // Apply bounce effect
                        />
                        <h1 className="text-3xl font-800 text-gray-400">
                            Preparing For <br />
                            <span className="font-800 text-cyan-400">Lift-Off!</span>
                        </h1>
                    </div>
                    <div className="flex justify-center gap-4 border-b pb-2 mb-4">
                        {['Admin', 'Client', 'Employee'].map((type) => (
                            <button
                                key={type}
                                onClick={() => setUserType(type.toLowerCase())}
                                className={`px-4 py-2 text-sm font-medium transition border-b-2 
                                    ${userType === type.toLowerCase() ? 'border-cyan-400 text-cyan-500' : 'border-transparent text-gray-600'}`}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                    {errorMessage && <p className="text-red-500 text-sm text-center mb-4">{errorMessage}</p>}
                    <form onSubmit={handleLogin} className="space-y-4">
                        <div>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your Login ID"
                                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:outline-none"
                                required
                            />
                        </div>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Password"
                                className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-cyan-400 focus:outline-none"
                                required
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-4 text-gray-600 text-sm"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                            </button>
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 bg-black text-white font-800 rounded-md hover:bg-gray-900"
                            disabled={loading}
                        >
                            {loading ? 'Logging in...' : `Login as ${userType}`}
                        </button>
                    </form>
                    <div className="text-center py-8 mt-4">
                        <p className="text-gray-600 text-sm mb-5">Or continue with</p>
                        <div className="flex justify-center gap-4">
                            <button
                                className="flex items-center px-4 py-2 text-white rounded-md gap-2 bg-google"
                                onClick={handleGoogleLogin}
                                disabled={loading}
                            >
                                <i className="fa fa-google"></i>
                                Google
                            </button>
                            <button
                                className="flex items-center px-4 py-2 text-white rounded-md gap-2 bg-meta"
                                onClick={handleFacebookLogin}
                                disabled={loading}
                            >
                                <i className="fa fa-meta"></i>
                                Meta
                            </button>
                        </div>
                    </div>
                    <button
                        onClick={() => navigate('/')}
                        className="mt-4 w-full py-2 bg-black text-white font-800 rounded-md hover:bg-gray-900"
                    >
                        Go to Home
                    </button>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default LoginPage;
