import React, { useState } from 'react';
import contactimg from "../assets/logo/Nexkites KiteBlue.png";
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';
import ProgressSlider from '../constants/ProgressSlider';

const services = [
    'Product Design',
    'Website Development',
    'Mobile Apps Development',
    'UI/UX Design',
    'Video & Animations',
];

const ContactSection = () => {
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        projectDetails: "",
    });

    const [selectedServices, setSelectedServices] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const toggleService = (service) => {
        setSelectedServices((prev) =>
            prev.includes(service)
                ? prev.filter((item) => item !== service)
                : [...prev, service]
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    const submitData = async (event) => {
        event.preventDefault();
        const { name, email, phone, address, projectDetails } = userData;

        // Validate if all fields are filled and at least one service is selected
        if (!name || !email || !phone || !address || !projectDetails) {
            alert("Please fill all the fields.");
            return;
        }

        if (selectedServices.length === 0) {
            alert("Please select at least one service.");
            return;
        }

        try {
            const res = await fetch(
                'https://nexkites-38efb-default-rtdb.firebaseio.com/userDataRecords.json',
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        phone,
                        address,
                        projectDetails,
                        services: selectedServices,
                    }),
                }
            );

            if (res.ok) {
                setUserData({
                    name: "",
                    email: "",
                    phone: "",
                    address: "",
                    projectDetails: "",
                });
                setSelectedServices([]);
                setShowSuccessModal(true);
            } else {
                alert("Failed to store data.");
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("An error occurred while submitting your data.");
        }
    };

    const closeModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <>
            <Navbar className="mb-16" />

            <section className="mt-16 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] py-10 px-4 sm:px-8 md:px-12 lg:px-16 flex flex-col md:flex-row justify-between items-center gap-8 mb-8 rounded-3xl shadow-xl selection:bg-cyan-300 selection:text-cyan-900">
                {/* Left Section */}
                <div className="w-full md:w-1/2 space-y-4">
                    <h3 className="text-4xl text-gray-700 font-semibold text-center p-5 mb-10">
                        Contact Us
                    </h3>
                    <p className="text-lg text-gray-600">
                        Let us know how we can help with your next project!
                    </p>

                    <div className=" p-5 rounded-xl">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Inquiry for Services</h3>
                        <p className='text-red-400 p-5'>Note: Select Services and fill your details in form</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            {services.map((service) => (
                                <button
                                    key={service}
                                    onClick={() => toggleService(service)}
                                    className={`w-full px-4 py-2 text-sm font-semibold rounded-lg transition-all shadow
                                        ${selectedServices.includes(service)
                                            ? 'bg-cyan-500 text-white border-transparent'
                                            : 'bg-white text-gray-700 border-2 border-gray-300 hover:bg-cyan-100'}`}
                                >
                                    {service}
                                </button>
                            ))}
                        </div>
                    </div>

                    <form className="space-y-2 mt-1 p-5 rounded-lg " onSubmit={submitData}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={userData.name}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={userData.email}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Your Phone"
                            value={userData.phone}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <input
                            type="text"
                            name="address"
                            placeholder="Your Address"
                            value={userData.address}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <textarea
                            name="projectDetails"
                            placeholder="Details of Your Project"
                            value={userData.projectDetails}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400 resize-none"
                            rows={4}
                        ></textarea>

                        <button
                            type="submit"
                            className="w-full py-3 bg-black text-white font-800 rounded-lg shadow-lg hover:bg-black transition-all"
                        >
                            Send Message
                        </button>
                    </form>
                </div>

                {/* Right Section (Image Centered on Top with Content Below) */}
                <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center space-y-6">
                    <img
                        src={contactimg}
                        alt="Contact us"
                        className="w-[100px] sm:w-[150px] md:w-[250px] lg:w-[250px] mb-8 bounce"
                    />
                    <h2 className="text-4xl sm:text-5xl lg:text-6xl p-5 font-semibold text-gray-800">
                        Have a <span className='text-cyan-500'>Project?</span> Let's Talk!
                    </h2>

                    {/* Company Support Email and Address */}
                    <p className="text-lg text-gray-600 mt-2">
                        Our office address:
                        <span className="text-gray-800 font-semibold"> CIDCO, Chh. Sambhajinagar,MH. IN - 431003</span>
                    </p>
                    <p className="text-lg text-gray-600 mt-4">
                        You can also reach us at:
                        <span href="mailto:nexkites@gmail.com" className="text-cyan-500 font-800"> nexkites@gmail.com</span>
                    </p>


                    {/* Right Section: Social Icons */}
                    <div className="flex items-center space-x-4 text-[25px] text-gray-600">
                        <a href="https://x.com/nexkites/" aria-label="Twitter" className="hover:text-cyan-500">
                            <i className="fab fa-x-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/nexkites/profilecard/?igsh=MXY3aHV4aXBqYmV6Mw==" aria-label="Instagram" className="hover:text-cyan-500">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.facebook.com/share/19J1f8JaNK" aria-label="Meta" className="hover:text-cyan-500">
                            <i className="fab fa-meta"></i>
                        </a>
                        <a href="https://www.threads.net/@nexkites?invite=4" aria-label="Thread" className="hover:text-cyan-500">
                            <i className="fab fa-threads"></i>
                        </a>
                        <a href="#" aria-label="Whatsapp" className="hover:text-cyan-500">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/nexkitesglobal/" aria-label="LinkedIn" className="hover:text-cyan-500">
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                    </div>

                </div>

            </section>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
                        <h2 className="text-2xl font-800 text-center text-green-600">
                            Success!
                        </h2>
                        <p className="text-center text-gray-700 mt-4">
                            Your message has been sent successfully.
                        </p>
                        <div className="mt-6 flex justify-center">
                            <button
                                onClick={closeModal}
                                className="px-6 py-2 bg-black text-white font-800 rounded-lg shadow-md hover:bg-black transition"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <ProgressSlider />
            <Footer />
        </>
    );
};

export default ContactSection;
