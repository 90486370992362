import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "../assets/logo/Nexkites NameKiteBlue.png";
import { HiMenu, HiX } from 'react-icons/hi';
import { FaUserCircle } from 'react-icons/fa';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const menuItems = [
        { name: 'Services', path: '/services' },
        { name: 'Pricing', path: '/pricing' },
        { name: 'About', path: '/about-us' },
        { name: 'Careers', path: '/careers' },
        { name: 'Support', path: '/support' },
    ];

    const isActive = (path) => location.pathname === path;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <nav className="bg-white fixed top-0 left-0 w-full py-3 shadow-md flex items-end px-6 lg:px-12 z-50 rounded-b-xl">
            {/* Logo Section */}
            <div className="flex items-end w-1/2 md:w-1/3 justify-start">
                <Link to="/">
                    <img
                        src={logo}
                        alt="Logo"
                        className="h-10 w-auto md:h-14 md:w-auto"
                    />
                </Link>
            </div>


            {/* Desktop Menu */}
            <div className="hidden md:flex justify-center w-1/2 md:w-2/3 items-end space-x-8 text-lg font-800">
                {menuItems.map(item => (
                    <Link
                        key={item.name}
                        to={item.path}
                        className={`text-black hover:text-cyan-400 ${isActive(item.path) ? 'text-cyan-500' : ''}`}
                    >
                        {item.name}
                    </Link>
                ))}
            </div>

            {/* User Icon */}
            <div className="flex items-end ml-auto">
                <Link to="/login-page" className="hidden md:flex items-center text-grey-300 hover:text-cyan-400">
                    <FaUserCircle size={24} />
                </Link>

                {/* Mobile Hamburger Button */}
                <button
                    onClick={toggleMenu}
                    className="md:hidden ml-4 text-black hover:text-cyan-400"
                    aria-expanded={isOpen}
                    aria-label={isOpen ? "Close menu" : "Open menu"}
                >
                    {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
                </button>
            </div>

            {/* Mobile Dropdown Menu */}
            {isOpen && (
                <div className="absolute top-14 right-4 bg-white shadow-md rounded-md w-48 md:hidden transition duration-200 ease-in-out">
                    <ul className="flex flex-col">
                        {menuItems.map(item => (
                            <li key={item.name}>
                                <Link
                                    to={item.path}
                                    className="block px-4 py-2 text-black hover:text-cyan-400"
                                    onClick={toggleMenu}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}

                        <li>
                            <Link
                                to="/login-page"
                                className="block px-4 py-2 text-grey-300 hover:text-cyan-400"
                                onClick={toggleMenu}
                            >
                                <FaUserCircle className="inline mr-2 hover:text-cyan-400" /> Login
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
