import React from 'react';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import ErrorLogo from "../assets/logo/Nexkites KiteBlue.png";

const UpcomingEvents = () => {
    return (
        <>
            <Navbar className="mb-16" />
            <div className="flex mt-16 flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 via-blue-50 to-white mt-10 px-4">
                <div className="relative">
                    <img
                        src={ErrorLogo}
                        alt="Nexkites logo"
                        className="w-40 max-w-xs animate-float drop-shadow-2xl rounded-lg mb-10"
                    />
                </div>

                <h1 className="text-3xl sm:text-4xl mt-5 text-center font-800 text-cyan-400 p-5 leading-relaxed animate-bounce">
                    Nexkites Team is Bringing Great Opportunities for You Soon!
                </h1>

                <p className="text-lg sm:text-xl text-gray-500 text-center mt-6 font-800">
                    Stay tuned! Exciting career opportunities are on the horizon.
                </p>
                <div className="w-full sm:w-auto mt-6">
                    <a
                        href="/"
                        className="block px-8 py-4 mx-auto bg-black text-white text-lg font-800 rounded-lg shadow-lg transform hover:scale-105 hover:shadow-2xl transition duration-300 ease-in-out text-center"
                    >
                        Go Back
                    </a>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default UpcomingEvents;
